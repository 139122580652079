<template>
  <div class="d-flex justify-content-center">
    <font-awesome-icon
      :icon="['fas', 'spinner']"
      class="display-4 d-flex justify-content-center fa-spin"
    ></font-awesome-icon>
  </div>
</template>

<script >
export default {};
</script>
      